import axios from "axios";

function get_fragrances() {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}template/get_fragrances`,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
  }

function get_products(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}template/get_products`,
    headers: { "Content-Type": "application/json" },
    data: data,
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error.response;
    });
}

function get_template(data) {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}template/get_template`,
      headers: { "Content-Type": "application/json" },
      data: data,
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response;
      });
  }

export { get_products, get_fragrances, get_template };
